<template>
    <div class="page-wrapper" id="scroll_top">
        <div class="prix_wrap" v-if="pageIsLoaded">
            <div class="title_wrap" data-inview="fadeInUp" data-delay="200">
                <h1 class="title">{{ content.entete1.titre }}</h1>
                <p class="regular-text">
                    <span v-html="content.entete1.texte"></span>
                </p>
            </div>
            <a :href="item.lien"
                class="prix"
                data-inview="fadeInUp"
                data-delay="200"
                v-for="(item, i) in content.listeInformationsEtAnnee"
                :key="i"
            >
                <div class="date">
                    <p class="title">{{ item.annee }}</p>
                </div>
                <div class="content">
                    <p class="regular-text bold">
                        <span v-html="item.titre"></span>
                    </p>
                    <p class="regular-text">
                        <span v-html="item.texte"></span>
                    </p>
                </div>
            </a>
        </div>

        <RelatedAbout v-if="pageIsLoaded" :route="$route.path" />
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import GLightbox from 'glightbox'
// import Flickity from 'flickity'
import RelatedAbout from '@/components/RelatedAbout'

export default {
    data() {
        return {
            lightboxelement: null,
        }
    },

    components: {
        RelatedAbout,
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        this.lightboxelement = GLightbox
                        GLightbox()

                        setTimeout(() => {
                            // this.teamCarousel.resize()
                        }, 1755)
                    }, 1055)
                }
            },
            immediate: true,
        },
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>

<style lang="scss" scoped></style>
