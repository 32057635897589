<template>
    <section class="other_wrap" data-inview="fadeInUp" data-delay="200">
        <div class="title_wrap left" data-inview="fadeInUp" data-delay="200">
            <h2 class="title small">{{ $t('À propos') }}</h2>
            <h3 class="title medium">
                <span v-html="$t('En savoir plus sur<br />St-Denis Thompson')"></span>
            </h3>
        </div>

        <router-link
            :to="item.boutonUrl[$route.meta.lang]"
            class="box"
            v-for="(item, i) in relatedContent"
            :key="i"
        >
            <div class="content">
                <p class="regular-text caps">{{ item.titre }}</p>
                <p class="regular-text">
                    <span v-html="item.texte.slice(0, 75) + '...'"></span>
                </p>
                <div class="cta">
                    <p class="regular-text">{{ $t('Informations') }}</p>
                    <img src="@/assets/img/arrow-right.svg" alt="arrow right" />
                </div>
            </div>
            <img
                :src="item.image.url"
                :alt="item.image.titre"
            />
        </router-link>
    </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import axios from 'axios'

export default {
    name: 'RelatedAbout',

    props: {
        route: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            relatedContent: []
        }
    },

    async mounted() {
        this.relatedContent = []

        const locale = this.$route.meta.lang === 'fr' ? '' : '/en'
        const baseUrl = '/api'
        const res = await axios.get(
            `${
                process.env.VUE_APP_SITE_BASE_URL + locale + baseUrl
            }/pages/aPropos.json`
        )

        this.relatedContent = [res.data.page.split1, res.data.page.split2, res.data.page.split3]
        this.relatedContent = this.relatedContent.filter(i => i.boutonUrl[this.$route.meta.lang] !== this.route)
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
